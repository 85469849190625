import React from "react";
import { Popover } from "antd";

import {
  FileProtectOutlined,
  SolutionOutlined,
  SettingOutlined,
  FileTextOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";

import { TBtnDDoc } from "./Types";
import { CSSIconDDoc } from "./Styles";

export const BtnDDoc: React.FC<TBtnDDoc> = ({ title, link, file, type }) => {
  const iconDoc = (type: string) => {
    switch (type.substring(type.length - 2)) {
      case "LA":
        return (
          <CSSIconDDoc>
            <FileProtectOutlined />
          </CSSIconDDoc>
        );
      case "UM":
        return (
          <CSSIconDDoc>
            <SolutionOutlined />
          </CSSIconDDoc>
        );
      case "FD":
        return (
          <CSSIconDDoc>
            <FileTextOutlined />
          </CSSIconDDoc>
        );
      case "DI":
        return (
          <CSSIconDDoc>
            <SettingOutlined />
          </CSSIconDDoc>
        );
      case "UA":
        return (
          <CSSIconDDoc>
            <FileDoneOutlined />
          </CSSIconDDoc>
        );
      default:
        return "Error. Type does't found.";
    }
  };

  return (
    <Popover
      placement="bottom"
      title={title}
      content={
        <div>
          <a href={link} download={file}>
            Скачать
          </a>
        </div>
      }
    >
      {iconDoc(type)}
    </Popover>
  );
};
